var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.getters.isEventAdmin(_vm.$store.state.user.currentUser.id) || _vm.$store.state.user.currentUser.admin > 0)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Поиск заявок","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"loading-text":"Загрузка","no-data-text":"Нет заявок"},scopedSlots:_vm._u([{key:"item.check_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment.utc(item.check_at).local().format("DD.MM.YYYY H:mm"))+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.event_check_roles.map(function (ecr) { return ecr.event_role.role.name; }).join(", "))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.user.role === 'student')?_c('div',[_vm._v(" Студент ")]):_vm._e(),(item.user.role === 'employee')?_c('div',[_vm._v(" "+_vm._s(item.user.employee_post)+" "),(item.department)?_c('span',[_vm._v(_vm._s(item.user.department.name))]):_vm._e()]):_vm._e(),(item.role === 'pupil')?_c('div',[_vm._v(" "+_vm._s(item.user.pupil_school)+" "+_vm._s(item.user.pupil_class)+" ")]):_vm._e(),(item.user.role === 'visitor')?_c('div',[_vm._v(" Посетитель ")]):_vm._e()]}},{key:"item.accept",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"green","outlined":"","small":""},on:{"click":function($event){return _vm.approve(item.id)}}},[_vm._v("Принять")])]}},{key:"item.decline",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","outlined":"","small":""},on:{"click":function($event){return _vm.decline(item.id)}}},[_vm._v("Отклонить")])]}}],null,false,1008193587)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }